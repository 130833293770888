import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import 'moment/locale/zh-cn';
import './index.less';
import * as serviceWorker from './serviceWorker';
import asyncComponent from './components/async-component';

const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>

const PageLogin = asyncComponent(() => import('./pages/login'), placeholder);
const PageHome = asyncComponent(() => import('./pages/home'), placeholder);
const PageCheck = asyncComponent(() => import('./pages/check'), placeholder);

const PageIssus = asyncComponent(() => import('./pages/issue'), placeholder);
const PageLtime = asyncComponent(() => import('./pages/ltime'), placeholder);

const PageAdmin = asyncComponent(() => import('./pages/admin'), placeholder);
const PageSystemLogs = asyncComponent(() => import('./pages/systemLogs'), placeholder);
const PagePermission = asyncComponent(() => import('./pages/permission'), placeholder);
const PageRole = asyncComponent(() => import('./pages/role'), placeholder);
const PageArea = asyncComponent(() => import('./pages/area'), placeholder);
const PageNotice = asyncComponent(() => import('./pages/notice'), placeholder);
const PageTimes = asyncComponent(() => import('./pages/times'), placeholder);
const PageSpecial = asyncComponent(() => import('./pages/special'), placeholder);
const PageMap = asyncComponent(() => import('./pages/map'), placeholder);

const App = () => (
	<Router>
		<Switch>
			<Route exact path="/"><PageHome /></Route>
			<Route exact path="/login"><PageLogin /></Route>
			<Route exact path="/check/list"><PageCheck /></Route>
			<Route exact path="/issue/list"><PageIssus /></Route>
			<Route exact path="/ltime/list"><PageLtime /></Route>
			<Route exact path="/admin/list"><PageAdmin /></Route>
			<Route exact path="/logs/list"><PageSystemLogs /></Route>
			<Route exact path="/map/list"><PageMap /></Route>
			<Route exact path="/permission/list"><PagePermission /></Route>
			<Route exact path="/role/list"><PageRole /></Route>
			<Route exact path="/area/list"><PageArea /></Route>
			<Route exact path="/notice/list"><PageNotice /></Route>
			<Route exact path="/times/list"><PageTimes /></Route>
			<Route exact path="/special/list"><PageSpecial /></Route>
			<Route render={() => (<Redirect to="/result/404" />)}/>
		</Switch>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
